<!-- 마이페이지 메인 -->
<template>
	<div class="mypage">
		<!--1. 달력 / 그래프 -->
		<div class="mypage-main__section--1">
			<div class="calender">
				<date-pick v-model="date" :hasInputElement="false"></date-pick>
			</div>
			<div class="mypage-main__service-inuse">
				<h2 class="mypage-main__sub-title">이용 중인 서비스</h2>
				<div class="graph">
					<img :src="graphsample" />
				</div>
			</div>
		</div>
		<!--2. 퀵서비스 -->
		<div class="mypage-main__section">
			<h2 class="mypage-main__sub-title">퀵서비스</h2>
			<div class="mypage-main__borderbox">
				<button class="btn__unset">
					<img :src="quickmenu1" /></button
				><button class="btn__unset">
					<img :src="quickmenu2" /></button
				><button class="btn__unset">
					<img :src="quickmenu3" /></button
				><button @click="toChangeQuickmenu4" class="btn__unset">
					<img :src="quickmenu4" />
				</button>
			</div>
		</div>

		<!--3. 새소식 -->
		<div class="mypage-main__section">
			<h2 class="mypage-main__sub-title news">새소식</h2>
			<table class="table__news">
				<tr>
					<td>새소식</td>
					<td>&lt;문의하기 &gt; &nbsp;자주 하시는 질문을 모았습니다.</td>
					<td>2022 - 03 - 05</td>
				</tr>
			</table>
		</div>
		<!--4. 이용 서비스 -->
		<div class="mypage-main__section">
			<h2 class="mypage-main__sub-title inservice">이용 서비스</h2>
			<div class="inservice__box">
				<table class="table__inservice">
					<tr>
						<th>날짜</th>
						<th>제품</th>
						<th>이용 서비스</th>
						<th>제품 시작일</th>
						<th>제품 만기일</th>
					</tr>
					<tr>
						<td>2022-05-06</td>
						<td>암호 스키마</td>
						<td>Elliptic Curve Homomorphic Cryptosystem</td>
						<td>2022-05-06</td>
						<td>2022-05-06</td>
					</tr>
					<tr>
						<td>2022-05-06</td>
						<td>암호 스키마</td>
						<td>Elliptic Curve Homomorphic Cryptosystem</td>
						<td>2022-05-06</td>
						<td>2022-05-06</td>
					</tr>
					<tr>
						<td>2022-05-06</td>
						<td>암호 스키마</td>
						<td>Elliptic Curve Homomorphic Cryptosystem</td>
						<td>2022-05-06</td>
						<td>2022-05-06</td>
					</tr>
				</table>
			</div>
		</div>
		<!--5. 광고 문구 -->

		<div class="mypage-main__section">
			<div class="mypage-main__banner-box">
				<div class="mypage-main__banner">
					<img :src="mypageadvertising01" />
				</div>
				<div class="mypage-main__banner">
					<img :src="mypageadvertising02" />
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import quickmenu1 from '@/assets/images/mypage/결제 내역.svg';
import quickmenu2 from '@/assets/images/mypage/부가 서비스.svg';
import quickmenu3 from '@/assets/images/mypage/이용 서비스.svg';
import quickmenu4 from '@/assets/images/mypage/회원정보.svg';
import graphsample from '@/assets/images/mypage/graphsample.svg';
import mypageadvertising01 from '@/assets/images/mypage/mypage_advertising01.png';
import mypageadvertising02 from '@/assets/images/mypage/mypage_advertising02.png';
import DatePick from 'vue-date-pick';
import 'vue-date-pick/dist/vueDatePick.css';

export default {
	components: { DatePick },
	data() {
		return {
			quickmenu1,
			quickmenu2,
			quickmenu3,
			quickmenu4,
			graphsample,
			mypageadvertising01,
			mypageadvertising02,
		};
	},
	methods: {
		toChangeQuickmenu4() {
			this.$router.push('/mypage/changeinfo').catch(() => {});
		},
	},
};
</script>

<style></style>
